<template>
  <b-card
    no-body
    class="system-settings-card prozess-todo"
  >
    <div>
      <sidebar-form
        :form-data-loading="metastatusLoading"
        :visible="showForm"
        :resource-id="resourceId"
        :status-options="statusOptions"
        :priority-options="priorityOptions"
        :category-options="categoryOptions"
        :source-options="sourceOptions"
        @saved="fetchList()"
        @close="onCloseForm"
      />
      <b-row
        class="m-2"
        align-h="between"
        align-v="baseline"
      >
        <b-col
          cols="12"
          lg="4"
        >
          <div class="d-flex">
            <div class="mr-1">
              <b-button
                id="create-button"
                variant="primary"
                :class="{ 'icon-disabled': $can('Create', 'Todo') === false }"
                @click="$can('Create', 'Todo') && create()"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                <span class="align-middle">{{ $t('Create New') }}</span>
              </b-button>
              <b-tooltip
                variant="dark"
                target="create-button"
              >
                {{ $can('Create', 'Todo') === true ? $t('Create') : $t('You dont have permission to create') }}
              </b-tooltip>
            </div>
            <div>
              <imex-actions-dropdown
                :schema-name="Schema.CORE"
                :entity-type="Entity.TODO"
                :recent-export-entity-type="Entity.TODO_COMPANY_CONTACT"
              />
            </div>
          </div>
        </b-col>
        <b-col
          cols="12"
          lg="8"
        >
          <div class="row-flex-align-start row-flex-justify-end tw-mt-4">
            <prozess-search-input
              class="tw-mr-4"
              :value="search"
              :placeholder="$t('Search')"
              @input="handleSearch"
            />

            <status-filter
              ref="statusFilter"
              style="width: 200px"
              :value="statusId"
              :status-options="statusOptions"
              @statusSelected="handleStatusFilter"
            />

            <date-range-filter
              ref="dateRangeFilter"
              class="tw-ml-4"
              style="width: 200px; padding-top: 1px"
              @applyDateFilter="applyDateFilter"
            />
          </div>
        </b-col>
      </b-row>
      <!-- <div class="m-2">
        <user-group-filter
          ref="userGroupFilter"
          @handleUserFilter="handleUserFilter"
          @handleGroupFilter="handleGroupFilter"
        />
      </div> -->
      <div class="m-2">
        <filter-input
          :filter-options="filterOptions"
          @applyFIlter="applyFIlter"
        />
      </div>
      <div
        v-if="!customizeLoading"
        class="m-2 prozess-todo__table"
      >
        <b-table
          ref="refTable"
          :busy="loading"
          :empty-text="$t('No matching records found')"
          :fields="tableColumns"
          :items="items"
          class="table-responsive"
          primary-key="id"
          responsive
          show-empty
          no-local-sorting
          @sort-changed="handleSortChange"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>

          <template #head()="{ label }">
            <span>{{ $t(label) }}</span>
          </template>

          <!-- START: for customizable fields -->

          <template #cell()="data">
            <router-link
              v-if="isTitle(data)"
              :id="`tooltip-${data.index}-${data.field.key}`"
              class="text-nowrap"
              :class="{ 'd-block max-w-200px text-truncate': data.field.type === 'LONGTEXT' }"
              :to="{
                name: 'todo-view',
                params: {
                  id: data.item.todoUuid,
                  tab: GenericTabs.OVERVIEW_360,
                },
              }"
            >
              {{ findValue(data) }}
            </router-link>

            <span
              v-else
              :id="`tooltip-${data.index}-${data.field.key}`"
              style="max-width: 200px"
              class="d-block"
              :class="{ 'text-primary': data.field.key.includes('mobile'), 'text-truncate': data.field.type === 'LONGTEXT' }"
            >
              {{ findValue(data) }}
            </span>
            <b-tooltip
              v-if="data.field.type === 'LONGTEXT'"
              :target="`tooltip-${data.index}-${data.field.key}`"
              :title="findValue(data)"
              placement="down"
            />
          </template>

          <!-- END: for customizable fields -->
          <template #cell(createdTimestamp)="{ item }">
            <prozess-field-wrapper class="tw-flex-1 tw-pt-3">
              <span
                :id="`tooltip-${item.todoId}`"
                style="max-width: 200px"
                class="d-block"
              >
                {{ item.todoId }}
              </span>
              <b-tooltip
                :target="`tooltip-${item.todoId}`"
                placement="down"
              >
                <span class="d-block">{{ $t('Todo ID') }} : {{ item.todoId }}</span>
                <span
                  v-if="item.createdByUserName"
                  class="d-block"
                >
                  {{ $t('Created By') }} : {{ item.createdByUserName }}
                </span>
                <span
                  v-if="item.createdTimestamp"
                  class="d-block"
                >
                  {{ $t('Date Time') }} : {{ $dayjs(item.createdTimestamp).format('YYYY-MM-DD HH:mm') }}
                </span>
              </b-tooltip>
            </prozess-field-wrapper>
          </template>

          <template #cell(statusName)="{ item }">
            <prozess-field-wrapper class="tw-flex-1 tw-pt-3">
              <prozess-select
                :searchable="false"
                :disabled="!$can('Update', 'Todo')"
                class="prozess-todo__dropdown"
                :value="item.statusName"
                style="flex: 1"
                :placeholder="$t('Status')"
                :options="statusOptions"
                label="metaStatusName"
                :clearable="false"
                @input="e => handleMetastatusInput('status', e, item.todoUuid)"
              >
                <template #selected-option="{ metaStatusName }">
                  <span>{{ metaStatusName }}</span>
                </template>
              </prozess-select>
            </prozess-field-wrapper>
          </template>

          <template #cell(priorityName)="{ item }">
            <prozess-field-wrapper class="tw-flex-1 tw-pt-3">
              <prozess-select
                :searchable="false"
                :disabled="!$can('Update', 'Todo')"
                class="prozess-todo__dropdown"
                :value="item.priorityName"
                style="flex: 1"
                :placeholder="$t('Priority')"
                :options="priorityOptions"
                :get-option-label="option => option.metaStatusName + ' - ' + option.metaStatusRank"
                label="metaStatusName"
                :clearable="false"
                @input="e => handleMetastatusInput('priority', e, item.todoUuid)"
              >
                <template #selected-option="{ metaStatusName, metaStatusRank }">
                  <span>{{ metaStatusName }} - {{ metaStatusRank || item.priorityRank }}</span>
                </template>
              </prozess-select>
            </prozess-field-wrapper>
          </template>

          <template #cell(categoryName)="{ item }">
            <prozess-field-wrapper class="tw-flex-1 tw-pt-3">
              <prozess-select
                :searchable="false"
                :disabled="!$can('Update', 'Todo')"
                class="prozess-todo__dropdown"
                :value="item.categoryName"
                style="flex: 1"
                :placeholder="$t('Category')"
                :options="categoryOptions"
                label="metaStatusName"
                :clearable="false"
                @input="e => handleMetastatusInput('category', e, item.todoUuid)"
              >
                <template #selected-option="{ metaStatusName }">
                  <span>{{ metaStatusName }}</span>
                </template>
              </prozess-select>
            </prozess-field-wrapper>
          </template>

          <template #cell(sourceName)="{ item }">
            <prozess-field-wrapper class="tw-flex-1 tw-pt-3">
              <prozess-select
                :searchable="false"
                :disabled="!$can('Update', 'Todo')"
                class="prozess-todo__dropdown"
                :value="item.sourceName"
                style="flex: 1"
                :placeholder="$t('Source')"
                :options="sourceOptions"
                label="metaStatusName"
                :clearable="false"
                @input="e => handleMetastatusInput('source', e, item.todoUuid)"
              >
                <template #selected-option="{ metaStatusName }">
                  <span>{{ metaStatusName }}</span>
                </template>
              </prozess-select>
            </prozess-field-wrapper>
          </template>

          <template #cell(companyName)="{ item }">
            <prozess-resource-name
              v-if="item.companyUuid"
              :id="`view-row-${item.companyUuid}-link`"
              class="text-nowrap d-inline-block"
              :to="{
                name: `company-view`,
                params: {
                  id: item.companyUuid,
                  tab: GenericTabs.OVERVIEW_360,
                },
              }"
              :title="item.companyName"
            />
          </template>

          <template #cell(contactFirstName)="{ item }">
            <prozess-resource-name
              v-if="item.contactUuid"
              :id="`view-row-${item.contactUuid}-link`"
              class="text-nowrap d-inline-block"
              :to="{
                name: `contact-view`,
                params: {
                  id: item.contactUuid,
                  tab: GenericTabs.OVERVIEW_360,
                },
              }"
              :title="(item.contactFirstName || '') + ' ' + item.contactLastName"
            />
          </template>

          <template #cell(assignedUserFirstName)="{ item }">
            <span v-if="item.assignedUserUuid">{{ item.assignedUserFirstName }} {{ item.assignedUserLastName }}</span>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="{ item }">
            <div class="text-nowrap">
              <feather-icon
                :id="`edit-row-${item[idName]}-preview-icon`"
                class="mx-1 cursor-pointer"
                icon="EditIcon"
                size="16"
                :class="{
                  'icon-disabled': $can('Update', 'Todo') === false,
                }"
                @click="$can('Update', 'Todo') ? edit(item[idName]) : null"
              />
              <b-tooltip
                :target="`edit-row-${item[idName]}-preview-icon`"
                :title="$can('Update', 'Todo') === true ? $t('Edit') : $t('You dont have permission to update')"
                placement="left"
              />
              <feather-icon
                :id="`delete-row-${item[idName]}-preview-icon`"
                class="mx-1 cursor-pointer"
                icon="TrashIcon"
                size="16"
                :class="{
                  'icon-disabled': $can('Delete', 'Todo') === false,
                }"
                @click="$can('Delete', 'Todo') ? remove(item) : null"
              />
              <b-tooltip
                :title="$can('Delete', 'Todo') === true ? $t('Delete') : $t('You dont have permission to remove')"
                :target="`delete-row-${item[idName]}-preview-icon`"
                placement="left"
              />
            </div>
          </template>
        </b-table>
      </div>
      <prozess-pagination
        v-if="!loading"
        class="mx-2 mb-2"
        :class="{ 'd-none': !total }"
        :meta="dataMeta"
        :page="currentPage"
        :total="total"
        :size="perPage"
        @change="handleCurrentPage"
      />
    </div>
  </b-card>
</template>

<script>
import { Schema, Entity, GenericTabs } from '@/constants/app'
import { swalConfirmDeleteOption } from '@/helpers/app'
import todoService from '@/services/todo'
import listMixinFactory from '@/mixins/list'
import metastatusDropdownMixin from '@/mixins/metastatusDropdown'
import SidebarForm from './Form.vue'
import ImexActionsDropdown from '@/views/shared/Imex/ImexActionsDropdown.vue'
import StatusFilter from '@/components/Lists/StatusFilter.vue'
import DateRangeFilter from '@/components/Lists/DateRangeFilter.vue'
import FilterInput from '@/components/Lists/FilterInput.vue'

export default {
  components: {
    SidebarForm,
    ImexActionsDropdown,
    FilterInput,
    StatusFilter,
    DateRangeFilter,
  },
  mixins: [
    metastatusDropdownMixin,
    listMixinFactory({
      routeName: 'todo',
      service: todoService,
      isCustomizable: true,
      tableColumns: [],
      listFormat: true,
      searchField: 'firstName',
      sort: {
        name: 'firstName',
        phone: 'phone',
      },
    }),
  ],
  data() {
    return {
      GenericTabs,
      idName: 'todoUuid',
      showView: false,
      Schema,
      Entity,
    }
  },
  mounted() {
    this.getMetastatusInitialData()
  },
  methods: {
    handleMetastatusInput(type, metastatus, id) {
      todoService.patch(id, {
        [`${type}Id`]: metastatus.metaStatusId,
      })
    },
    async create() {
      this.getMetastatusInitialData()
      this.resourceId = null
      this.showForm = true
    },
    handleRemove(uuid, version) {
      this.$swal({
        title: this.$t('Do you also want to delete the related Notes and Documents of this to do?'),
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        denyButtonText: this.$t('No'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-secondary',
          denyButton: 'btn btn-dark ml-1',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        const { isConfirmed: removeTodoWithDocumentAndNote, isDenied: removeTodoOnly } = result

        if (removeTodoOnly) {
          this.deleteResource(uuid, version, {
            versionKey: 'todoVersion',
          })
        } else if (removeTodoWithDocumentAndNote) {
          todoService
            .removeWithDocumentsAndNotes(uuid, {
              todoVersion: version,
            })
            .then(() => this.fetchList())
        }
      })
    },
    remove({ todoUuid, todoVersion }) {
      if (this.$can('Delete', 'Todo') === false) return
      this.$swal(swalConfirmDeleteOption(v => this.$t(v))).then(result => {
        if (result.value) {
          this.handleRemove(todoUuid, todoVersion)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/master-variables.scss';
@import '@/assets/scss/master-variables.scss';

.max-w-200px {
  max-width: 200px;
}

.prozess-todo {
  span {
    white-space: nowrap;
  }

  &__table {
    .b-table-sticky-column {
      left: unset !important;
      right: 0 !important;
    }
    td.b-table-sticky-column {
      background: var(--colour--table-row) !important;
    }
  }
}

.prozess-todo__dropdown {
  .vs--open {
    div > div > .vs__selected {
      margin-top: 10px !important;
    }
  }
  div > div > div.vs__selected-options {
    height: 35px;
    overflow: hidden;
    width: 140px;
    .vs__selected {
      margin-top: 0px;
      height: 100%;
    }
  }
}
</style>
